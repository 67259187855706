<script>
  import {
    required,
    email,
    helpers
  } from "@vuelidate/validators";
  import appConfig from "../../../app.config";
  import serverConfig from '@/helpers/config.js'
  import { mapActions} from "vuex";
  //import Swal from "sweetalert2";
  //import axios from 'axios';
  export default {
    setup() {
      const backgroundImg = `url('${serverConfig.EP}public/assets/img/background-logo.png')`
      let tk = 'Bearer '+localStorage.getItem('tk')

      return { backgroundImg,tk };
    },
    page: {
      title: "Login",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    data() {
      return {
        config:serverConfig,
        email: "",
        password: "",
        submitted: false,
        authError: null,
        tryingToLogIn: false,
        isAuthError: false,
        pswShow:false,
      };
    },
    validations: {
      email: {
        required: helpers.withMessage("Email obbligatoria", required),
        email: helpers.withMessage("Inserisci una email valida, per favore", email),
      },
      password: {
        required: helpers.withMessage("Password obbligatoria", required),
      },
    },
    mounted(){
      document.getElementById('auth-particles').style.backgroundImage = this.backgroundImg;
    },
    methods: {
      ...mapActions('auth', ['logIn', ]),
      ...mapActions('layout', ['changeLayoutType', ]),
      pswVis(){
        var x = document.getElementById("password-input");
        if (x.type === "password") {
          this.pswShow = false
          x.type = "text";
        } else {
          this.pswShow = true
          x.type = "password";
        }
      },
      signinapi() {
          this.logIn({email:this.email,password:this.password})
      },

    },
  };
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>
    </div>
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img :src="config.EP+'/public/assets/img/logo-light.png'" alt="" :height="config.IMG_HEIGHT" />
                </router-link>
              </div>
              <p class="mt-3 fs-15 fw-medium">
              </p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                
                </div>
                <div class="p-2 mt-4">
                  <b-alert v-model="authError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>

                  <div>

                  </div>

                  <form @submit.prevent="tryToLogIn">
                    <div class="mb-3">
                      <label for="email" class="form-label">Email</label>
                      <input type="email" class="form-control" id="email" placeholder="Inserisci email" v-model="email" />
                      <div class="invalid-feedback">
                        <span></span>
                      </div>
                    </div>

                    <div class="mb-3">
                      <div class="float-end">
                        <router-link to="/forgot-password" class="text-muted">Password dimenticata?</router-link>
                      </div>
                      <label class="form-label" for="password-input">Password</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input type="password" v-model="password" class="form-control pe-5" placeholder="Inserisci password"
                          id="password-input" />
                        <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                          type="button" id="password-addon">
                          <i class="ri-eye-fill align-middle" @click="pswVis"></i>
                        </button>
                        <div class="invalid-feedback">
                          <span></span>
                        </div>
                      </div>
                    </div>

                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                      <label class="form-check-label" for="auth-remember-check">Ricordami
                        </label>
                    </div>

                    <div class="mt-4">
                      <button class="btn btn-primary w-100" type="submit" @click="signinapi">
                        Accedi
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->

            <div class="mt-4 text-center">

            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{ new Date().getFullYear() }} 
               <a href="https://www.neuralika.com" target="blank">Neuralika</a> 
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>
